#userListDiv .e-grid .e-rowcell{
    overflow: visible;
  }
  #userListDiv th.e-headercell {
    background: #295d65 !important;
    color: #fff;
  }
  #userListDiv th.e-headercell > div > span{
    font-weight: 700;
    font-size: 16px;
  }