body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

caption {
  display: none;
}

span.e-input-group:has(input.e-disable) {
  display:none;
}

#btnOpenModal.e-control.e-btn.e-lib {
	border-radius: 20px;
	height: 40px;
	height: 40px;
}

div#modalcontainer {
	float: inline-end;
}

#modalcontainer #btnOpenModal img {
	padding-bottom: 3px; 
	padding-left: 1px; 
}
/* Default styles */
.btn-theme, .e-filter-popup.e-btn.e-primary {
  color: #fff !important;
  background-color: #295d65 !important;
  border-color: #295d65 !important;
}

/* Hover state */
.btn-theme:hover, .e-filter-popup.e-btn.e-primary:hover {
  color: #fff !important;
  background-color: #477177 !important;
  border-color: #33676f !important;
}

/* Focus state */
.btn-theme:focus, .e-filter-popup.e-btn.e-primary:focus {
  color: #fff !important;
  background-color: #477177 !important;
  border-color: #33676f !important;

}

/* Active state */
.btn-theme:active, .e-filter-popup.e-btn.e-primary:active {
  color: #fff !important;
  background-color: #33676f !important;
  border-color: #33676f !important;
}

/* Disabled state */
.btn-theme:disabled, .e-filter-popup.e-btn.e-primary:disabled {
  color: #fff;
  background-color: #295d65 !important;
  border-color: #295d65 !important;
  opacity: 0.65;
}
/* Default styles */
.btn-outline-theme, .e-filter-popup.e-btn.e-flmenu-cancelbtn {
  color: #295d65 !important;
  border-color: #295d65 !important;
  background-color: transparent !important;
}

/* Hover state */
.btn-outline-theme:hover, .e-filter-popup.e-btn.e-flmenu-cancelbtn:hover {
  color: #fff !important;
  background-color: #295d65 !important;
  border-color: #295d65 !important;
}

/* Focus state */
.btn-outline-theme:focus, .e-filter-popup.e-btn.e-flmenu-cancelbtn:focus {
  color: #fff !important;
  background-color: #295d65 !important;
  border-color: #295d65 !important;
}

/* Active state */
.btn-outline-theme:active, .e-filter-popup.e-btn.e-flmenu-cancelbtn:active {
  color: #fff !important;
  background-color: #295d65 !important;
  border-color: #295d65 !important;
}

/* select option:checked, select option:hover {
  background-color: #477177 !important;
} */

/* select option:focus {
  background-color: green;
} */

/* Disabled state */
.btn-outline-theme:disabled, .e-filter-popup.e-btn.e-flmenu-cancelbtn:disabled {
  color: #295d65 !important;
  background-color: transparent;
  border-color: #295d65 !important;
  opacity: 0.65;
}

.text-theme {
	color:#295d65 !important;
}
.bg-theme{
  background-color: #295d65;
}
.form-control{
  appearance: auto !important;
}
.form-control:focus{
  box-shadow: none !important;
  border-color: #295d65 !important;
}
.dropdown-toggle::after {
  display: none !important;
}
.e-pager .e-currentitem{
  /* border: 1px solid #295d65 !important; */
  color: #295d65 !important;
  background-color: #fff !important;
  border-color: #295d65 !important;
  font-weight: bold !important;
}
div.e-gridpager.e-control.e-pager.e-lib > div.e-pagercontainer > div.e-numericcontainer > div > a{
  /* border: 1px solid #295d65 !important; */
  color: #295d65 !important;
}
.e-link .e-numericitem .e-spacing .e-currentitem .e-active{
  border-color: #295d65 !important;
}
.e-pager div.e-icons.e-pager-default {
  color: #295d65 !important;
}

.e-pager .e-current-item {
  border-right-color: #477177 !important;
}
.e-frame.e-icons.e-check{
  background-color: #295d65 !important;
  border-color: #295d65 !important;
}
.Toastify__toast--success {
  border-left: 8px solid #3C9826;
  border-radius: 8px !important;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
:root {
  --toastify-icon-color-success:#3C9826 !important;
  --toastify-icon-color-warning:#F7B84F !important;
  --toastify-icon-color-error:#f00 !important;
}
.Toastify__toast--warning{
  border-left: 8px solid #F7B84F;
  border-radius: 8px !important;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.Toastify__toast--error{
  border-left: 8px solid #f00;
  border-radius: 8px !important;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(.e-icon-reorderdownarrow),
.e-grid .e-filtered::before {
  color: #fff !important;
}
.e-treeview .e-list-item.e-active>.e-fullrow{
  background-color: transparent !important;
  border-color: transparent !important;
}
.e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) {
  background: #295d65 !important;
  border-color: #295d65 !important;
  color: #fff;
}
.spinner-large {
  color: #477177;
  width: 3rem !important; 
  height: 3rem !important;
}
.e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) {
  background: #295d65 !important;
  border-color: #295d65 !important;
  color: #fff;
}
.hidden {
  display: none !important;
}
.e-avatar-xlarge {
  width: 128px !important;
  height: 128px !important;
  font-size: 46px !important;
  border: 1px solid #477177;
}
.e-treeview .e-list-item.e-active>.e-text-content .e-icon-expandable {
  color: #6c757d !important;
}