.ManageRolesColumns {
	float: "left";
	display: "inline-block";
}

#btnOpenModal.e-control.e-btn.e-lib {
	border-radius: 20px;
	height: 40px;
}

div#modalcontainer {
    float: none;
    position: absolute;
    right: 20px;
    bottom: 40px;
}

#modalcontainer #btnOpenModal img {
	padding-bottom: 3px; 
	padding-left: 1px; 
}
#rolesGrid th.e-headercell {
    background: #295d65 !important;
    color: #fff;
}
#rolesGrid th.e-headercell > div > span{
    font-weight: 700;
    font-size: 16px;
}
#rolesGrid .e-grid .e-rowcell{
    overflow: visible !important;
}
.dropdown-toggle::after {
    display: none;
  }