body {
    font-family: Arial, sans-serif;
    background-color: #fff;
}

.container {
    max-width: 500px !important;
    height: auto !important;
    margin: auto;
    padding: 20px !important;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.176);
    border-radius: 0px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

h1 {
    color: #333;
}

p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}

.button-container {
    text-align: center;
    margin-top: 20px;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #477177;
    text-decoration: wavy;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 5px;
}

    .button:hover {
        background-color: #fff;
        color: #477177;
        border: 1px solid #477177;
    }