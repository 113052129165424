/* 
.RolesList {
	width: 50%;
} */

#rolesGrid th.e-headercell {
    background: #295d65 !important;
    color: #fff;
}
#rolesGrid th.e-headercell > div > span{
    font-weight: 700;
    font-size: 16px;
}
.e-grid .e-rowcell{
    overflow: visible !important;
}

.dropdown-menu.show {
    position:fixed !important;
}